$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$custom-primary-palette1: (
  50: #e4e9ef,
  100: #bbc7d8,
  200: #90a2bd,
  300: #667fa2,
  400: #446591,
  500: #1c4c82,
  600: #134579,
  700: #073c6e,
  800: #003362,
  900: #00234b,
  A100: #0096ea,
  A200: #0083d5,
  A400: #0072c1,
  A700: #00539F,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);
$custom-primary-palette2: (
  50: #e6f6ea,
  100: #c4e9cb,
  200: #9ddaab,
  300: #74cd89,
  400: #52c26f,
  500: #2bb655,
  600: #21a74b,
  700: #129540,
  800: #008435,
  900: #006420,
  A100: #9cd791,
  A200: #71c862,
  A400: #1daf00,
  A700: #0ba000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);
$custom-primary-palette3: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
); 

@use '@angular/material' as mat;
@import '../node_modules/@angular/material/prebuilt-themes/cyan-orange.css';


$custom-primary: mat.m2-define-palette($custom-primary-palette1, 800);
$custom-accent: mat.m2-define-palette($custom-primary-palette2, 700);
$custom-warn: mat.m2-define-palette($custom-primary-palette3);

$custom-theme: mat.m2-define-light-theme((
 color: (
   primary: $custom-primary,
   accent: $custom-accent,
   warn: $custom-warn,
 ),
 typography: mat.m2-define-typography-config(),
 density: (
    scale: 1
  )
));

@include mat.all-component-themes($custom-theme);