/* You can add global styles to this file, and also import other style files */
@use "../node_modules/bootstrap/scss/bootstrap";

:root,
[data-bs-theme=light] {
    --bs-primary: $primary;
    --bs-primary-rgb: 0, 51, 98;
    --bs-secondary-color: #21A74C;
    --bs-tertiary-bg: #D8DEE4;
    --bs-tertiary-color: rgba(156, 174, 191, 1);
    --bs-tertiary-color-rgb: 156, 174, 191;
    --bs-secondary-color-rgb: 51, 181, 107;
    --mdc-outlined-button-label-text-tracking: 0;
    --mdc-filled-button-label-text-tracking: 0;
    --mdc-text-button-label-text-tracking: 0;
    --mdc-switch-track-width: 52px;
    --mdc-switch-track-height: 32px;
    --mdc-switch-track-shape: 9999px;
    --mdc-switch-handle-shape: 9999px;
    --mat-switch-unselected-handle-horizontal-margin: 0 8px;
    --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 3px;
    --mat-switch-unselected-pressed-handle-horizontal-margin: 0 3px;

    --mat-toolbar-container-background-color: #fff;
    --mat-tree-node-min-height: 30px;
    --mat-form-field-container-height: 38px;
    --mat-form-field-filled-label-display: block;
    --mat-form-field-container-vertical-padding: 10px;
    --mat-form-field-filled-with-label-container-padding-top: 10px;
    --mat-form-field-filled-with-label-container-padding-bottom: 4px;
    --mat-form-field-container-text-size: 14px;
    --mat-form-field-outlined-label-text-populated-size: 14px;
    --mat-paginator-container-size: 56px;
    --mat-paginator-form-field-container-height: 33px;
    --mat-paginator-form-field-container-vertical-padding: 6px;
    --mat-form-field-container-text-line-height: 15px;
    --mat-select-trigger-text-size: 14px;
    --mat-select-trigger-text-line-height: 23px;
    --mat-option-label-text-size: 14px;
    --mdc-outlined-text-field-label-text-size: 14px;
    --mat-table-header-container-height: 35px;
    --mat-table-footer-container-height: 35px;
    --mat-table-row-item-container-height: 35px;
    --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(var(--bs-tertiary-color-rgb), 0.2), 0px 8px 10px 1px rgba(var(--bs-tertiary-color-rgb), 0.14), 0px 3px 14px 2px rgba(var(--bs-tertiary-color-rgb), 0.12);
    --mat-table-row-item-outline-color: rgb(38 38 38 / 15%);
    --mdc-dialog-supporting-text-color: rgba(var(--bs-primary-rgb), 1);
    --mat-menu-item-label-text-size: 14px;
    --mdc-switch-handle-width: 26px;
    --mat-checkbox-label-text-color: #000;
    --mdc-checkbox-state-layer-size: 30px;
    --bs-success-bg-subtle: #b8d5b0;
    --bs-success-border-subtle: #8eb983;

    --bs-danger-bg-subtle: #f8d7da;
    --bs-danger-border-subtle: rgb(221, 147, 155);

    .mdc-switch .mdc-switch__handle-track {
        width: calc(100% - var(--mdc-switch-handle-width));
    }

    .mdc-switch.mdc-switch--unselected .mdc-switch__handle-track {
        margin: 0 3px;
    }

    .mat-mdc-slide-toggle .mdc-switch__handle:has(.mdc-switch__icons) {
        --mat-switch-pressed-handle-size: 24px;
        --mat-switch-with-icon-handle-size: 24px;
    }

    .mat-mdc-header-cell {
        box-shadow: 0px -1px 0px 0px rgb(var(--bs-primary-rgb)) inset;
    }

    .mat-mdc-outlined-button.mat-primary {
        --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0);

        &.active {
            border-color: rgba(var(--bs-secondary-color-rgb), 1) !important;
        }

        &.outline {
            border-color: rgba(var(--bs-primary-color-rgb), 1) !important;

        }
    }

    .mat-mdc-checkbox .mat-internal-form-field {
        --mat-checkbox-label-text-color: #212529;
    }

    .filter {
        .mat-mdc-form-field.mat-accent {
            --mat-select-enabled-trigger-text-color: #ECECEC;
            --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
            --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
        }

        .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
            --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
        }

        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-floating-label,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-floating-label--float-above {
            --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
        }

        .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
            --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
        }

        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
            --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
        }
    }

    .mat-primary.mat-mdc-checkbox {
        --mat-checkbox-label-text-color: #000 !important;
    }
}

$white :#fff;

// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900
.min-h44 {
    min-height: 44px;
}

.red-snackbar {
    .mat-mdc-snackbar-surface {
        background: #f8d7da !important;
        color: #003362 !important;
    }
}

.green-snackbar {
    .mat-mdc-snackbar-surface {
        background: #b8d5b0 !important;
        color: #003362 !important;
    }
}

.exo-font {
    font-optical-sizing: auto;
    font-style: normal;
    font-family: "Exo", sans-serif !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 700;
}

.fw-900 {
    font-weight: 700;
}

.hint-hide {
    .mdc-label {
        color: $white !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
    padding: 0 40px !important;
}

.mat-mdc-table .mat-mdc-header-row {
    background: #DEEEFC !important;
    color: rgba(var(--bs-primary-rgb), 1) !important;

}

.mdc-dialog__title::before {
    height: 32px !important;
}

.main-wrapper {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto auto 1fr auto;
}

.min-w-auto {
    min-width: auto !important;
}

.bg-body-tertiary {
    background-color: var(--bs-tertiary-bg) !important;
}

.mat-mdc-outlined-button {
    border-radius: 25px !important;

    &.btnlg {
        border-radius: 4px !important;
        --mdc-outlined-button-container-height: 44px;
    }

    &.rds4 {
        border-radius: 4px !important;
    }
}

div.mat-mdc-select-panel {
    padding: 0 !important;
    border-radius: 8px;
}

.mat-mdc-menu-content {
    padding: 0 !important;
    border-radius: 8px;
}

.mat-elevation-z8,
.mat-mdc-elevation-specific.mat-elevation-z8 {
    box-shadow: var(--mat-select-container-elevation-shadow);
}

.mat-mdc-option {
    min-height: 33px !important;
}

.example-context-area {
    display: inline-grid;
    border: 2px dashed black;
}

.example-context-area .example-context-area {
    margin: 100px;
    width: 200px;
    height: 100px;
}

.context-menu {
    display: inline-flex;
    flex-direction: column;
    min-width: 180px;
    max-width: 280px;
    background-color: rgb(255, 255, 255);
    padding: 0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: var(--mat-select-container-elevation-shadow);
}

.close-btn {
    position: absolute !important;
    right: 0;
    top: 0;
    padding: 5px;
}

.context-menu-item {
    background-color: transparent;
    cursor: pointer;
    border: none;
    outline: 0;
    user-select: none;
    min-width: 64px;
    line-height: 36px;
    padding: 0 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex: 1;
    color: rgb(var(--bs-primary-rgb));
    font-weight: 500;
}

.context-menu-item:hover {
    background-color: rgb(208, 208, 208);
}

.context-menu-item:active {
    background-color: rgb(170, 170, 170);
}

.mat-mdc-dialog-title {
    background-color: rgb(var(--bs-primary-rgb));
}

.mat-mdc-form-field {
    width: 100%;
}

// .mat-mdc-button{
//     letter-spacing:0 !important
// }
///----------------
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
    padding-right: calc(var(--bs-gutter-x)* 1.7);
    padding-left: calc(var(--bs-gutter-x)* 1.7);
}

.container-fluid {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.header-button {
    display: flex;
    gap: 5px;

    button {
        text-transform: uppercase;
    }
}

.user-btn {
    margin-right: -10px;
}

.example-spacer {
    flex: 1 1 auto;
}

.material-symbols-outlined {
    vertical-align: middle;
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24
}

.btn-icon {
    .material-symbols-outlined {
        font-size: 20px;
        margin-right: 8px;
        font-variation-settings:
            'opsz' 20
    }
}

.filter {
    .mdc-label {
        color: $white !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
}

mat-grid-tile {
    background-color: #D8DEE4;
}

.flex1 {
    flex: 1;
}

.footer {
    font-size: .8em;
}

app-header {

    position: sticky;
    top: 0;
    z-index: 10;

    border-bottom: var(--bs-gray-300) 1px solid;
}

app-dashboard,
app-rollback {
    display: grid;
    grid-auto-rows: auto 1fr;
}

.tree-block {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding-right: calc(var(--bs-gutter-x)* 0.5);
    padding-left: calc(var(--bs-gutter-x)* 0.5);

    @media screen and (max-width: 1024px) {
        position: relative;
        padding-right: 0;
        padding-left: 0;
        margin-bottom: 1.5rem !important;
    }

    .card-header {
        padding-top: 3px;
        padding-bottom: 2px;
    }
}

.custom-tree-menu {
    overflow: auto;

    .mat-mdc-icon-button.mat-mdc-button-base {
        --mdc-icon-button-state-layer-size: 30px;
        padding: 0;
    }

    .mat-mdc-icon-button .mat-mdc-button-touch-target {
        width: 30px;
        height: 30px;
    }
}

.o-visible {
    overflow: visible !important;
}

.card-footer {
    .mat-mdc-paginator {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

.fix-five-table {
    .card-body {
        max-height: 210px;
        overflow: auto;

        .mat-mdc-table thead .mat-mdc-header-cell {
            position: sticky;
            top: 0;
            font-weight: 600;
        }
    }
}

.profile-menu {
    min-width: 250px !important;
}

.mob-navigation {
    position: none;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    background: transparent !important;

    &.mat-drawer-container-has-open {
        position: fixed !important;
    }

    .sidenav {
        min-width: 300px;

        .mat-mdc-outlined-button {
            width: 100%;
            justify-content: left;
        }

        .material-symbols-outlined,
        .mat-icon {
            min-width: 30px;
            margin: 0 !important;
        }

        .user-btn {
            margin: 0;
        }
    }
}

.action-col {
    width: 130px;
    text-align: center !important;
}

.table-fix {
    @media screen and (max-width: 768px) {
        max-width: calc(100vw - 57px);
    }

    max-width: calc(100vw - 82px);
    overflow: auto;

    .mat-mdc-table .mat-mdc-header-cell,
    .mat-mdc-cell {
        white-space: nowrap;
    }
}

.pointer {
    cursor: pointer;
}

$primaryColor: var(--bs-secondary-color);
$secondaryColor: #fff;

.menu-items {
    overflow: hidden;

    button {
        justify-content: flex-start;
        border-radius: 0;
        position: relative;

        &.active {

            &::before {
                background-color: #21A74C;
                height: 100%;
                content: "";
                width: 3px;
                display: block;
                left: 0;
                position: absolute;
                top: 0;
            }
        }
    }
}

.cursor-default {
    cursor: default !important;
}

.mat-tree-node:hover {
    background-color: #ede8e852;
}

.mat-mdc-row:hover {
    background-color: #c8f9dd;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.hidden-folder {
    display: inline-flex;
    width: 40px;
    height: 20px;
}

.mat-mdc-paginator {
    border-top: 1px solid #dfdfdf;
}

.checkbox-tree {
    li {
        list-style: none;
    }

}

.select38 {
    .mat-mdc-text-field-wrapper {
        height: 38px !important;
    }
}

.select-form-menu {
    .mat-mdc-icon-button.mat-mdc-button-base {
        --mdc-icon-button-state-layer-size: 30px;
        padding: 0;
    }

    .mat-mdc-icon-button .mat-mdc-button-touch-target {
        width: 30px;
        height: 30px;
    }
}

.fix-table{
    max-height: calc(100vh - 280px);
    overflow-y: auto;
}